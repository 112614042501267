.login_wrap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height:100%;
  width: 100%;
}
.login_con{
  width: 80%;
  align-self: center;
}
.login_input_title{
  text-align: left;
  color:#a4a4a4;
  font-size: 12px;
  line-height: 13px;
  font-weight: 400;
  margin-top:10px;
}
.login_input_wrap{
  width: 100%;
  text-align: center;
  margin: 5px 0px;
}
.login_input_wrap input{
  border-radius: 5px;
  padding:0px 15px;
  width: 100%;
  background: #EBEBEB;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  font-weight: 400;
}
.login_input_wrap input:focus{
  outline: none;
  border-color: #7BCA55 ;
}
.login_btn{
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  background: linear-gradient(93deg, #9BBA40 34.09%, #7BCA55 121.26%);
  padding:4px;
  color:#fff;
  font-size:16px;
  cursor: pointer;
  margin-top:8px;
  height: 54px;
  font-weight: 500;
}
.login_bottom_btn_wrap{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top:10px;
}
.login_bottom_btn{
  margin-top:15px;
  text-align: center;
  line-height: 22px;
  border-radius: 15px;
  margin: 2px;
  padding:5px;
  color:#9BBA40;
  font-size:12px;
  border:1px solid #ddd;
  background: #fff;
  cursor: pointer;
}
.login_bottom_btn:nth-child(1){
  flex-grow: 1;
}
.login_bottom_btn:nth-child(2){
  flex-grow: 1;
}
.login_bottom_btn:nth-child(3){
  flex-grow: 1;
}