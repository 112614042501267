.top_area_wrap{
  position: relative;
  width: 100%;
  text-align: center;
  line-height: 50px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
}
.top_area_back_btn{
  position: absolute;
  left:10px;
  top:0px;
  font-size: 15px;
  font-weight: normal;
  color:#717D17;
  cursor: pointer;
}
.top_area_plus_btn{
  position: absolute;
  right:10px;
  top:-3px;
  cursor: pointer;
}
.top_area_plus_btn img{
  width: 20px;
  height: 20px;
}


.time_wrap{
  width: 100%;
  margin-top: 30px;
}
.time_con{
  position: relative;
  width: 300px;
  height: 350px;
  margin: 0 auto;
}

.time_top_circle_back{
  position: absolute;
  top:0px;
  left:0px;
  width: 300px;
  height: 300px;
  border-radius: 300px;
}

.total_per_circle{
  position: absolute;
  top:0px;
  left:0px;
  width: 300px;
  height: 300px;
}
.total_per_circle_box{
  position: relative;
  width: 100%;
  height: 100%;
}
.total_per_circle_svg{
  position:absolute;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%;
  transform: rotate( -270deg );
}

.time_top_per_wrap{
  position: absolute;
  width: 100%;
  top:-18px;
  left: 0px;
  text-align: center;
}
.time_top_per_text{
  display: inline-block;
  width: 55px;
  line-height: 30px;
  color:#9DAF23;
  background:#fff;
  border-radius: 15px;
  font-size: 15px;
  font-weight: 600;
}
.time_start_wrap{
  position: absolute;
  width: 100%;
  bottom:25px;
  left: 0px;
  text-align: center;
  z-index: 10;
}
.time_start_text{
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  color:#A5B72F;
  background:#FFF;
  border-radius: 50px;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

.time_center_wrap{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top:50px;
}
.time_center_title{
  color: #fff;
  font-size: 15px;
  margin-top: 10px;
}
.time_center_target_text{
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}
.time_center_target_amount_span{
  display: inline-block;
  cursor: pointer;
}
.time_center_target_amount_input{
  width: 40px;
  text-align: center;
  font-size: 16px;
}
.time_center_elapse_text{
  color: #fff;
  font-size: 30px;
  font-weight: bold;
}
.time_success_btn{
  position: absolute;
  top:350px;
  width: 100%;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color:#fff;
  background: #A5B72F;
  cursor: pointer;
}

.bottom_btn_wrap{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding:0px 20px;
}
.bottom_btn{
  display: inline-flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 99px;
  background: #E4E4E4;
  font-weight: 500;
  flex-grow: 1;
  cursor: pointer;
}
.bottom_btn.active{
  background: #B9CA42;
  color: #fff;
}

.bottom_wrap{
  position: relative;
  width: 100%;
  min-height: 100px;
  background: #fff;
  border-radius: 25px;
  padding:30px 20px;
  margin-top: 80px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 30px;
}
.bottom_right_subject{
  position: absolute;
  right: 10px;
  top:28px;
  font-size: 15px;
  color:#4c540e;
}
.bottom_title{
  color:#000;
  font-size: 15px;
  font-weight: bold;
}
.bottom_con_table table{
  width: 100%;
  border-collapse: collapse;
}
.bottom_con_table table th,.bottom_con_table table td{
  line-height: 35px;
  font-size: 14px;
  border:1px solid rgb(230, 230, 230);
  padding:0 10px;
}
.bottom_con_table table th{
  text-align: center;
  background: #ffffff;
  color: #717D17;
  font-weight: 500;
}
.bottom_con_table table td{
  color: #4c540e;
}
.file_area_wrap{
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 25px;
  padding:30px 20px;
  margin-bottom: 10px;
  font-size: 15px;
}
.file_area_title{
  font-size: 16px;
  font-weight: 500;
}