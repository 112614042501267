.group_list_wrap{
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding:5px;
  background: #F1F4F4;
}

.group_box_wrap{
  background: #fff;
  padding:7px;
  border-radius: 5px;
}

.card_box_list_wrap{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
  padding-left: 5px;
}
.card_box_wrap{
  border:1px solid #ddd;
  padding:5px;
}

.daily_input_wrap{
  display: flex;
  margin-top: 4px;
}
.daily_input_title{
  display: inline-block;
  min-width: 35px;
  font-size: 11px;
  text-align: right;
  padding-right: 1px;
  padding-left: 1px;
}
.daily_input_div{
  flex-grow: 1;
}