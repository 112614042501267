.center_wrap{
  padding:10px;
  padding-bottom: 80px;
}

.img_slide_wrap{
  position: relative;
  height: 250px;
}
.img_slide_item{
  width: 100%;
  height: 100%;
}
.img_slide_item img{
  width: 100%;
}

.title_wrap{
  color: #0D0D0D;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
}
.content_wrap{
  position: relative;
  color: #1d1d1d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
}
.box_content_title{
  color:#898989;
  font-weight: 500;
}

.bottom_wrap{
  position: fixed;
  bottom: 0px;
  left:0px;
  width: 100%;
  background: #fff;
}
.content_con{
  position: relative;
  width: 94%;
  margin:0 auto;
  font-size: 18px;
  padding:5px;
}
.content_con input{  
  width: 100%;
  border:0;
}

.button_wrap{
  border-top:1px solid #CAD5D2;
  padding:10px 0px;
  height: 70px;
  line-height: 50px;
  display: flex;
  gap: 10px;
  font-size: 13px;
}
.button_wrap_btn{
  border-radius: 99px;
  opacity: 0.9;
  background: #353535;
  color:#fff;
  padding:0 20px;
  font-size: 16px;
}