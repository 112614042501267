.stu_select_wrap{
  position: relative;
  display: flex;
  align-self: center;
  justify-content: center;
  gap:15px;
  vertical-align: 122px;
}

.top_count_wrap{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 22px;
  text-align: center;
}
.top_count{
  display: inline-block;
  width: 40px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  color:#fff;
  background: #6075E3;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 500;
}

.arrow{
  width: 14px;
  height: 20px;
  flex-shrink: 0;
}

.center_thum_wrap{
  position: relative;
  width:122px;
  height: 122px;
  flex-shrink: 0;
}

.center_thum_back_circle{
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background:#686868;
  opacity: 0.1;
}
.center_thum_circle{
  position: absolute;
  top:8px;
  right:8px;
  bottom:8px;
  left:8px;
  border-radius: 100px;
  background:#ffffff;
  opacity: 0.3;
}
.title_wrap{
  position: relative;
  margin-top:8px; 
}
.title_con{
  width: 100%;
  text-align: center;
  color:#fff;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.408px;
  font-weight: 500;
}