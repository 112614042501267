.bottom_wrap{
  position: relative;
}
.select_date_div{
  position: relative;
  line-height: 30px;
  font-size: 17px;
  color:#9e9e9e;
  margin-top: 10px;
}
.todo_write_btn{
  position: absolute;
  right: 5px;
}
.todo_table{
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
}
.todo_table tr{
  border-top:1px solid #c0c0c0;
  border-bottom:1px solid #c0c0c0;
}
.todo_table td{
  text-align: center;
  padding:4px;
  font-size: 16px;
}
.todo_row{
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.detail_table{
  width: 100%;
  border-collapse: collapse;
}
.detail_table tr{
  border:0;
}
.detail_table th,.detail_table td{
  text-align: left;
}