.row-input{
  border: 1px solid #ddd;
  padding: 0px 5px;
  height: 30px;
  line-height: 21px;
  font-size: 13px;
}
.select_none_arrow{
  appearance: none;
}
.date_picker_write{
  border: 1px solid #ddd;
  padding: 0px 5px;
  height: 21px;
  line-height: 21px;
  font-size: 13px;
}
.write-table-div table{
  width: 100%;
  border-collapse: collapse;
}
.write-table-div th,.write-table-div td{
  line-height: 30px;
  border-top:1px solid #ddd;
  border-bottom:1px solid #ddd;
  padding:3px 5px;
  font-size: 14px;
}
.write-table-div th{
  border-right: 1px solid #ddd;
}

.select_box_bar{
  display: flex;
  flex-wrap: wrap;
  gap:5px;
}
.select_box_bar_item{
  min-width: 25px;
  height: 25px;
  line-height: 25px;
  padding:0px 3px;
  text-align: center;
  background: #a7a7a7;
  color:#fff;
  font-size: 14px;
  cursor: pointer;
}
.select_box_bar_item:hover{
  background: rgb(0, 222, 207);
}
.select_box_bar_item.active{
  background: #00968b;
}

.write_table_base{
  width: 100%;
}
.write_table_base table{
  width: 100%;
  border-collapse: collapse;
}
.write_table_base.table_border table{
  border-collapse: collapse;
  border-radius: 5px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #d8d8d8;
}
.write_table_base th, .write_table_base td{
  border:1px solid #d8d8d8;
  padding:2px 2px;
  min-width: 35px;
  font-size: 14px;
  text-align: left;
}
.write_table_base th{
  font-weight:600;
  text-align: center;
}

.write_table_small{
  width: 100%;
}
.write_table_small table{
  width: 100%;
  border-collapse: collapse;
}
.write_table_small th, .write_table_small td{
  border:1px solid #d8d8d8;
  padding:2px 2px;
  min-width: 50px;
  font-size: 14px;
}
.write_table_small th{
  @apply bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-200;
  text-align: center;
}
.write_table_small td{
  @apply dark:bg-gray-800 dark:text-gray-400;
  font-weight: 400;
}

.write_table_small .row-input{
  @apply bg-gray-50 border border-gray-300 text-gray-900
  focus:ring-blue-500 
  focus:border-blue-500 
  w-full
  dark:bg-gray-700 
  dark:border-gray-600 
  dark:placeholder-gray-400 
  dark:text-white 
  dark:focus:ring-blue-500 
  dark:focus:border-blue-500;
  padding:3px 3px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
}

.write_select_tab{
  display: flex;
  flex-wrap: wrap;
  gap:5px;
}
.write_select_tab_item{
  flex-grow: 1;
  text-align: center;
  line-height: 25px;
  padding:0px 5px;
  background: #a7a7a7;
  color:#fff;
  font-size: 14px;
  cursor: pointer;
}
.write_select_tab_item:hover{
  background: rgb(0, 222, 207);
}
.write_select_tab_item.active{
  background: #00968b;
}

.select_box_bar{
  display: flex;
  flex-wrap: wrap;
  gap:5px;
  justify-content: center;
}
.select_box_bar_item{
  min-width: 25px;
  height: 25px;
  line-height: 25px;
  padding:0px 3px;
  text-align: center;
  background: #a7a7a7;
  color:#fff;
  font-size: 14px;
  cursor: pointer;
}
.select_box_bar_item:hover{
  background: rgb(0, 222, 207);
}
.select_box_bar_item.active{
  background: #00968b;
}

.select_box_bar_small{
  display: flex;
  flex-wrap: wrap;
  gap:2px;
  justify-content: center;
}
.select_box_bar_item_small{
  min-width: 16px;
  height: 20px;
  line-height: 20px;
  padding:0px 2px;
  text-align: center;
  background: #a7a7a7;
  color:#fff;
  font-size: 12px;
  cursor: pointer;
}
.select_box_bar_item_small:hover{
  background: rgb(0, 222, 207);
}
.select_box_bar_item_small.active{
  background: #00968b;
}

.select_box_bar_wide{
  display: flex;
  flex-wrap: wrap;
  gap:2px;
  justify-content: center;
}
.select_box_bar_item_wide{
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 25px;
  height: 25px;
  line-height: 25px;
  padding:0px 2px;
  text-align: center;
  background: #a7a7a7;
  color:#fff;
  font-size: 14px;
  cursor: pointer;
}
.select_box_bar_item_wide:hover{
  background:#69ff52;
}
.select_box_bar_item_wide.active{
  background: #00968b;
}