.total_wrap{
  font-size: 18px;
}
.top_info_wrap{
  text-align: center;
  position: relative;
}
.top_info_title{
  font-size: 24px;
  font-weight: bold;
}

.section_wrap{
  padding:0px 10px;
}
.section_title{
  position: relative;
  color:#9f9f9f;
  font-size: 20px;
  margin-top:12px;
  cursor: pointer;
}
.study_detail_box{
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 45px;
  line-height: 45px;
  background: #eee;
  border-radius: 5px;
  margin-top: 12px;
  padding: 0px 15px;
  padding-right: 60px;
}
.study_detail_right{
  position: absolute;
  right:0px;
  top:0px;
  height: 45px;
  width: 60px;
  text-align: center;
  color:"green";
  font-size: 18px;
}
.detail_view_title{
  font-size: 20px;
  font-weight: bold;
}

.map_detail_wrap{
  padding-left: 10px;
}
.map_detail_title{
  font-size: 20px;
  font-weight: bold;
  margin-top: 12px;
}
.map_detail_con{
  font-size: 18px;
  margin-top: 12px;
}

.map_detail_memo_span{
  display: inline-block;
  padding:0px 5px;
  border-radius: 5px;
  color:#fff;
  background: #9f9f9f;
  margin-left: 10px;
  margin-top:4px;
}