.card_list_wrap{
    display: flex;
    flex-direction: column;
    gap:10px;
    padding:5px;
}
.card_box{
  position: relative;
  border:1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}
.box_row{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.box_row_content{
  flex-grow: 1;
}
.box_row_right_area{
  width: 80px;
  text-align: right;
}

.content_input{
  width: 100%;
  border: 0;
}
.input_select{
  border: 1px solid #ddd;
}