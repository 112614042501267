.content_wrap{
  position: relative;
  font-size: 14px;
}
.content_con{
  position: relative;
  width: 94%;
  margin:0 auto;
  padding:5px;
  font-size: 14px;
}
.content_title{
  color:#777;
  font-weight: bold;
  font-size: 13px;
  margin-top: 5px;
}
.content_con input{  
  width: 100%;
  border:0;
  padding:0 5px;
}
.content_con textarea{
  padding:0 5px;
}