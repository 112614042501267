.chat_view_wrap {
  position: fixed;
  top:0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  padding-top:45px;
  padding-bottom: 60px;
}


.chat_view_header {
  position: fixed;
  top:0px;
  left: 0px;
  right: 0px;
  height: 45px;
  line-height: 45px;
  display: flex;
}

.chat_view_msg_wrap {
  flex-grow: 1;
  border-top: 1px solid #ddd;
  background-color: #f2f2f2;
  overflow: auto;
  width: 100%;
}

.chat_view_send_wrap {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-top: 1px solid #ddd;
  display: flex;
}

/* 헤더 */
.chat_view_header_pre_box{
  width: 50px;
  height: 100%;
  cursor: pointer;
  background: #ffffff;
  color:#000000;
  text-align: center;
}
.chat_view_header_title {
  flex-grow: 1;
  text-align: center;
}
.chat_view_header_mem_box{
  width: 50px;
  height: 100%;
  cursor: pointer;
  background: #4a4a4a;
  color:#fff;
  text-align: center;
}

.chat_content_row_date_text{
  text-align: center;
  color:#4f4f4f;
}

/* 메세지 리스트 */
.chat_view_msg_row{
  width: 100%;
  text-align: left;
  margin: 5px 0px;
  padding-right:8px;
}
.chat_view_msg_row.my_msg_row{
  text-align: right;
}
.chat_view_msg_row_title_box{
  position: relative;
  height: 35px;
  line-height: 35px;
}
.chat_view_msg_row_title_profile_img_box{
  position: absolute;
  display: inline-block;
  top:0px;
  left:0px;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  overflow: hidden;
}
.chat_view_msg_row_title_profile_img_box img{
  width: 100%;
}
.chat_view_msg_row_title{
  font-size: 18px;
  font-weight: 500;
  padding-left:40px;
}
.chat_view_msg_row_con_box{
  padding-left:40px;
}
.chat_view_msg_row_con_msg{
  display: inline-block;
  padding: 5px 15px;
  border-radius: 0px 15px 15px 15px;
  background: #E4EAE9;
  color: #000;
  font-size: 15px;
  min-height: 32px;
  max-width: 80%;
}
.my_msg_row .chat_view_msg_row_con_msg{
  border-radius: 15px 15px 0px 15px;
  background: #9CBB41;
  color: #fff;
}
.chat_view_msg_row_con_time{
  font-size: 15px;
  color: gray;
  padding: 0 2px;
  font-weight:500;
}
.chat_view_msg_row_con_unread{
  font-size: 15px;
  color: #f59342;
}

.chat_view_date_row{
  margin-top: 5px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}

.chat_view_date_row_text{
  display: inline-block;
  padding: 5px 10px;
  background: #dddddd;
  color: #5d5d5d;
  border-radius: 5px;
  font-size: 18px;
}

.chat_view_msg_row_img_box img{
  max-width: 100%;
}

/* 풋터 */
.chat_view_send_file_box {
  width: 50px;
  text-align: center;
}

.chat_view_send_input_box {
  flex-grow: 1;
  text-align: center;
}

.chat_view_send_input{
  width: 98%;
  height: 100%;
  margin: 0;
  padding: 0 10px;
  padding-top:20px;
  font-size: 16px;
  color:black;
  line-height: 16px;
}

.chat_view_send_btn_box {
  width: 80px;
  text-align: center;
  background: #9CBB41;
  font-weight: 500;
  font-size:18px;
  color:#fff;
  cursor: pointer;
}
/* 멤버 리스트 */
.chat_view_mem_wrap{
  position: fixed;
  width: 250px;
  right: 0px;
  top:0px;
  bottom:0px;
  background: #e0e0e0;
  opacity: 0.95;
  display: none;
}
.chat_view_mem_wrap.active{
  display: block;
}
.chat_view_mem_con{
  position: relative;
  width: 100%;
  height: 100%;
  padding:3px 15px;  
}
.chat_view_mem_list_wrap{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.chat_view_mem_list_item{
  position: relative;
  color:#000;
  font-size: 18px;
}
.chat_view_mem_list_item.active{
  color: #2002ff;
}
.chat_user_list_row_right{
  position:absolute;
  right: 0px;
  top:0px;
}
.chat_view_mem_bottom_wrap{
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 50px;
  border-top:1px solid #ccc;
  display: flex;
  flex-direction: row;
}