.top_date_wrap{
  text-align: center;
  font-size: 12px;
  color:#888;
  padding:3px;
}
.stu_top_wrap{
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.stu_circle_wrap{
  position: relative;
  height: 80px;
  width: 80px;
  display: inline-block;
  text-align: center;
}
.total_per_circle{
  position: absolute;
  top:0px;
  left:0px;
  width: 80px;
  height: 80px;
}
.total_per_circle_box{
  position: relative;
  width: 100%;
  height: 100%;
}
.total_per_circle_svg{
  position:absolute;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%;
  transform: rotate( -90deg );
}
.total_per_text{
  margin-top: 18px;
  font-size: 12px;
}
.total_per_text_num{
  font-size: 18px;
  font-weight: 600;
  line-height: 10px;
}
.stu_top_su_name{
  font-size: 15px;
  font-weight: 600;
  color:#000;
  margin-top:5px;
}

.todo_arr_table{
  margin:0 auto;
  margin-top:2px;
}
.todo_arr_table table{
  width: 100%;
  border-collapse: collapse;
}
.todo_arr_table th,.todo_arr_table td{
  line-height: 30px;
  text-align: center;
}
.todo_arr_table th{
  color:#000;
  font-size: 13px;
}
.todo_arr_table td{
  font-size: 13px;
  color:#707070;
}
.todo_arr_th_first{
  position: relative;
  width: 100%;
  padding-left: 6px;
  text-align: left;
  white-space: nowrap;
}
.todo_arr_th_first_bar{
  position: absolute;
  display: block;
  width: 3px;
  height: 100%;
  left:0px;
  top:0px;
  background: #B3C211;
}
.todo_arr_td_input{
  text-align: center;
  width:90%;
  padding:0 2px;
}

.bottom_btn_area{
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 10;
  background: #fff;
  text-align: center;
  padding:10px 0px;
  padding-bottom: 30px;
}