@tailwind base;
@tailwind components;
@tailwind utilities;

@import './css/base.css';
@import './css/btn.css';
@import './css/paging.css';
@import './css/popup.css';
@import './css/table.css';

@import './css/mobile/base.css';
@import './css/mobile/layout.css';
@import './css/mobile/sub_tab.css';
@import './css/mobile/list_base.css';
@import './css/mobile/list_board.css';
@import './css/mobile/list_search.css';
@import './css/mobile/chat/chat_view.css';
@import './css/mobile/sche_table.css';
@import './css/mobile/write.css';