.header{
  background: #FFF;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
}
.header_con{
  position: relative;
  min-height: 42px;
  line-height: 42px;
  width: 98%;
  margin:0 auto;
  text-align: center;
  font-size: 16px;
  color:#000000;
}
.header_left{
  position: absolute;
  top:0px;
  left:0px;
  cursor: pointer;
}