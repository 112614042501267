.select_sound_wrap {
  font-size: 18px;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  background: #e2e2e2;
}

.sound_list_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.sound_list_item {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 40px;
  color: #000;
  border: 1px solid #4c754e;
  width: 100%;
  padding: 0px 10px;
  cursor: pointer;
}

.sound_list_item.active {
  background: #f8fff8;
  background: #4c754e;
  color: #dcdcdc;
}

.sound_list_item_title {
  flex-grow: 1;
  font-size: 16px;
  line-height: 40px;
}

.sound_list_item_manage {
  text-align: center;
}