.intro_wrap{
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.intro_img_wrap{
  align-self: center;
  height: 300px;
  width: 100%;
  position: relative;
  text-align: center;
}
.intro_img_char{
  position:absolute;
  z-index: 1;
}
.intro_img_shadow{
  position:absolute;
  top:160px;
  width: 50px;
}
.intro_bottom_text{
  position:absolute;
  top:180px;
  left:0px;
  width: 100%;
  text-align: center;
  padding: 0px 5px;
  font-size: 45px;
  line-height: 40px;
  color:black;
}