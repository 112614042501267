.detail_title{
  font-size: 15px;
  font-weight: 600;
  color: #434343;
}
.detail_box_wrap{
  padding:10px;
  padding-left: 20px;
  margin-top: 10px;
  background: #ddd;
  font-size: 13px;
  font-weight: 500;
}

.supply_row_list{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.supply_row{
  display: flex;
  gap: 2px;
}
.supply_row_pre{
  width: 45px;
  background: #15c257;
  color: #fff;
  text-align: center;
  padding:0px 2px;
  border-radius: 5px;
}
.supply_row_title{
  flex-grow: 1;
}
.supply_row_money{
  display: inline-block;
  width: 100px;
  text-align: right;
  color: #15c257;
}