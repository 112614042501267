.title_area{
  width: 100%;
  font-size: 14px;
}
.title_area_con{
  width: 100%;
  padding:15px;
  padding-bottom: 0px;
  position: relative;
}
.title_text_wrap{
  font-size: 14px;
  color: #000;
}
.title_text_sub{
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.title_stat_box{
  position: relative;
  margin-top: 10px;
  background: rgb(134,166,9);
  background: linear-gradient(90deg, rgba(134,166,9,1) 0%, rgba(164,188,21,1) 51%, rgba(194,211,71,1) 100%);
  border-radius: 10px;
  padding:15px;
  padding-bottom: 0px;
  color:#fff;
  font-size: 12px;
}
.title_stat_box_sub_text{
  position: relative;
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  margin-top: 5px;
}
.title_stat_box_sub_text_per_text{
  position: absolute;
  right: 0px;
  top:2px;
  font-size: 13px;
}
.per_bar_wrap{
  position: relative;
  margin-top: 10px;
  width: 100%;
  height: 5px;
}
.per_bar_back{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background: #d3df8b;
}
.per_bar_stat{
  position: absolute;
  top:0px;
  left:0px;
  width: 0%;
  height: 100%;
  background: #fafbf2;
}

.stat_wrap{
  margin-top:15px;
  display: flex;
  border-top:1px solid #b2c535;
}
.stat_wrap_item{
  flex-grow: 1;
  text-align: center;
  border-left: 1px solid #b2c535;
  line-height: 30px;
}
.stat_wrap_item:first-child{
  border-left: 0px;
}