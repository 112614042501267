.card_list_wrap{
  padding:10px 15px;
}
.card_list_con{
  position: relative;
  width: 100%;
}
.card_list{
  display: flex;
  gap:10px;
  flex-direction: column;
}
.card_box{
  position: relative;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.11);
}
.card_box_textarea{
  line-height: 22px;
  margin-top: 5px;
  border:1px solid #ccc;
  font-size: 15px;
}
.card_box .header{
  border-bottom: 0.5px solid rgba(218, 218, 218, 0.50);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.03);
}
.card_box .header_con{
  position: relative;
  height: 40px;
  line-height: 40px;
  width: 94%;
  margin:0 auto;
  text-align: center;
  font-size: 17px;
}
.card_box .header_left{
  position: absolute;
  top:0px;
  left:0px;
  cursor: pointer;
}
.card_box .header_right{
  position: absolute;
  right:0px;
  top:0px;
  cursor: pointer;
}

.eval_wrap{
  position: relative;
}
.eval_con{
  padding:15px;
}
.eval_write_table{
  border-collapse: collapse;
  width: 100%;
}
.eval_write_table th,.eval_write_table td{
  font-size: 15px;
  text-align: left;
  line-height: 35px;
  color:#373737;
}
.eval_write_table th{
  font-weight: 500;
  color:#000;
}

/* Write */
.score_tag_box{
  display: flex;
}
.score_tag{
  min-width: 10%;
  flex-grow: 1;
  line-height: 35px;
  border-radius: 35px;
  background: #CAD5D2;
  color:#fff;
  font-size: 14px;
  padding:0 5px;
  margin:2px;
  text-align: center;
  cursor: pointer;
}
.score_tag.active{
  background: #3FAC96;
}

.card_box_btn_div{
  text-align: center;
}

.list_item{
  position: relative;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.11);
}
.list_item .header{
  border-bottom: 0.5px solid rgba(218, 218, 218, 0.50);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.03);
}
.list_item .header_con{
  position: relative;
  height: 40px;
  line-height: 40px;
  width: 94%;
  margin:0 auto;
  text-align: center;
  font-size: 17px;
}