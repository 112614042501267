.daily_wrap{
  border:1px solid #eee;
  border-radius: 5px;
  padding: 3px;
}
.daily_row_wrap{
  display: flex;
  gap:5px;
}
.daily_row_item{
  flex:1;
  text-align: left;
  padding-left: 20px;
  position: relative;
  margin-top: 2px;
}
.daily_row_item .item_title{
  position: absolute;
  top:0px;
  left:0px;
  width: 20px;
  height: 100%;
  display: inline-block;
  border:1px solid #eee;
  text-align: center;
  color:#747474;
  font-weight: 500;
}
.daily_row_item .input_view{
  width: 100%;
  height: 100%;
  padding: 0 2px;
  border:0px;
  font-size: 13px;
}
.daily_row_item .input_view:focus{
  position: absolute;
  min-width: 200px;
  left:0px;
  z-index: 10;
}