.matter_wrap{
  text-align: center;
  font-size: 35px;
  font-weight: 500;
}
.matter_answer_span{
  display: inline-block;
  padding:0px 10px;
  margin-left: 10px;
  background: #ffffff;
  color:blue;
  border-radius: 5px;
  min-width: 50px;
}