@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 100;
  font-style: normal;
  src: url("./font/noto_sans/NotoSans-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 200;
  font-style: normal;
  src: url("./font/noto_sans/NotoSans-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 300;
  font-style: normal;
  src: url("./font/noto_sans/NotoSans-DemiLight.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 400;
  font-style: normal;
  src: url("./font/noto_sans/NotoSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 500;
  font-style: normal;
  src: url("./font/noto_sans/NotoSans-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 700;
  font-style: normal;
  src: url("./font/noto_sans/NotoSans-Black.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 900;
  font-style: normal;
  src: url("./font/noto_sans/NotoSans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: 'KNPSKkomi';
  src: url('./font/kkomi/KNPSKkomi.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body{
  font-family: 'Noto Sans Korean','Noto Sans KR', sans-serif;
}

body .noto_sans{
  font-family: 'Noto Sans Korean','Noto Sans KR', sans-serif;
  letter-spacing: 0;
}

body img,body video{
  display: inline-block;
}

body h1,h2,h3,h4,h5{
  white-space:nowrap;
  font-weight: bold !important;
}
body h1{
  @apply text-3xl;
}
body h2{
  @apply text-2xl;
}
body h3{
  @apply text-xl;
}
body h4{
  @apply text-lg;
}
body h5{
  @apply text-base;
}

body a{
  cursor: pointer;
}

body input{
  border:1px solid #ddd;
}