.bottom_wrap{
  position: fixed;
  bottom: 0px;
  left:0px;
  width: 100%;
  padding:15px 0px;
  background: #fff;
}
.content_wrap{
  position: relative;
}
.content_con{
  position: relative;
  width: 94%;
  margin:0 auto;
  font-size: 18px;
  padding:5px;
}
.content_con input{  
  width: 100%;
  border:0;
}

.button_wrap{
  border-top:1px solid #CAD5D2;
  padding:10px 0px;
  height: 50px;
  line-height: 50px;
  display: flex;
  gap: 10px;
  font-size: 13px;
}
.button_wrap_btn{
  border-radius: 99px;
  opacity: 0.9;
  background: #353535;
  color:#fff;
  padding:0 20px;
  font-size: 16px;
}