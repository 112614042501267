.main_wrap{
  position: relative;
}
.top_title_wrap{
  width: 100%;
  margin-top: 30px;
  padding:0px 20px;
}
.top_title_con{
  position: relative;
  width: 100%;
}
.top_title_greet_wrap{
  color:#fff;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.408px;
}
.top_title_btn_wrap{
  position: absolute;
  top:0px;
  right: 0px;
}
.todo_today_wrap{
  width: 100%;
  margin-top: 20px;
  padding:0px 20px;
}
.todo_today_con{
  position: relative;
  border-radius: 20px;
  background: #FFF;
  width: 100%;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.20);
  min-height: 100px;
  color:#000;
  font-size: 14px;
  font-weight: 200;
  line-height: 25px;
  padding:18px 20px;
  padding-bottom:73px;
}
.todo_today_progress_bar_wrap{
  position: relative;
  width: 100%;
  height: 7px;
  border-radius: 10px;
  background:#8ae8cd;
}
.todo_today_progress_bar{
  position: absolute;
  top:0px;
  left:0px;
  height: 7px;
  width: 30%;
  border-radius: 10px;
  background: #3FAC96;
}
.todo_today_bottom{
  position: absolute;
  top:100px;
  left:0px;
  border-top:1px solid #ddd;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.todo_today_bottom .tot_div{
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color:#3FAC96;
  line-height: 22px;
  padding-top:12px
}
.tot_div .sub_text{
  color:#000;
  font-size: 12px;
  font-weight: 300;
}

.counsel_today_wrap{
  width: 100%;
  margin-top: 20px;
  padding:0px 20px;
}
.counsel_today_title{
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.counsel_today_list{
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top:10px;
  overflow: auto;
  padding-bottom: 5px;
}
.counsel_today_list_item{
  width: 90px;
  height: 100px;
  text-align: center;
  color:#fff;
  border-radius: 10px;
  background: #8F8F8F;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 22px;
  font-weight: 300;
}
.counsel_today_list_item.is_active{
  background: linear-gradient(180deg, #3FAC96 19.79%, #45BB97 100%);
}
.counsel_today_list_item:first-child{
  margin-left: 20px;
}
.counsel_box_img_wrap{
  text-align: center;
  margin-top:10px;
  height: 24px;;
  margin-bottom:10px;
}
.counsel_box_img_wrap svg{
  display: inline-block;
}
.counsel_box_stu_name{
  font-size: 13px;
}
.main_box_wrap{
  width: 100%;
  margin-top: 20px;
  padding:0px 20px;
}
.main_box_con{
  position: relative;
  width: 100%;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
  min-height: 50px;
  padding:18px 20px;
  padding-top:0px;
}
.main_box_title{
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
}
.main_box_title_right{
  position: absolute;
  top:0px;
  right: 0px;
  line-height: 40px;
}
.attend_tot_wrap{
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top:1px solid #dcdcdc;
}
.attend_tot_item{
  width: 25%;
  text-align: center;
  font-size: 15px;
  padding-top: 10px;
  color: #000;
}
.attend_tot_item_title{
  font-weight: 500;
  text-align: center;
  font-size: 18px;
}
.attend_tot_item_sub_text{
  font-size: 12px;
  color:#373737;
  margin-top:5px;
}
.todo_chart_box{
  position: relative;
  width: 100%;
  border-top:1px solid #dcdcdc;
  height:160px;
  font-size: 13px;
}

.area_wrap{
  width: 100%;
  margin-top: 20px;
  padding:0px 20px;
}
.area_wrap_con{
  position: relative;
  border-radius: 20px;
  background: #FFF;
  width: 100%;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.20);
  min-height: 100px;
  color:#000;
  font-size: 14px;
  font-weight: 200;
  line-height: 25px;
  padding:18px 20px;
}

.stodo_list_wrap{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.stodo_card_box{
  padding:5px;
  border-radius: 5px;
  line-height: 20px;
  border:1px solid #cfcfcf;
}