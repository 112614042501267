.view-table-div {
  width: 100%;
}

.view-table-div table {
  width: 100%;
  border-collapse: collapse;
}

.view-table-div th,
.view-table-div td {
  font-size: 14px;
  border: 1px solid #d6d6d6;
  padding: 5px;
  line-height: 30px;
}

.view-table-div th {
  font-weight: 600;
  background: #ebebeb;
}