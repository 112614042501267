.main_wrap{
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background: #D3DB90;
}

.top_name_wrap{
  position: absolute;
  top:0px;
  left:0px;
  padding:25px;
  width: 100%;
}
.top_name_date{
  font-size: 15px;
  font-weight: 400;
  color:#000200;
}
.top_name_title{
  font-size: 22px;
  font-weight: 500;
  color:#030804;
}

.top_progress_wrap{
  position: relative;
}
.top_name_comp_name{
  font-size: 13px;
  font-weight: 400;
}

.point_progress{
  position: relative;
  display: inline-block;
  min-width: 45px;
  height: 25px;
  line-height: 22px;
  border-radius: 5px;
  background: #fff;
  text-align: left;
  padding:0px 12px;
  margin-left:15px;
}
.point_progress_icon{
  position: absolute;
  height: 30px;
  left:-15px;
  top:-5px;
}
.point_progress_text{
  font-size: 12px;
  font-weight: 500;
  color:#000200;
}
.point_progress_bar_wrap{
  position: relative;
  display: inline-block;
  width: 70px;
  height: 8px;
  background: #D0DB8C;
  border-radius: 5px;
  margin-left: 10px;
}
.point_progress_bar{
  position: absolute;
  height: 100%;
  width: 0px;
  top:0px;
  left:0px;
  border-radius: 5px;
  background: #A5B72D;
}
.top_name_right_btn_area{
  position: absolute;
  right: 12px;
  top:12px;
  text-align: right;
}
.top_name_right_btn{
  position: relative;
  margin-left: 2px;
}
.top_name_option_btn_badg_cnt{
  position: absolute;
  right:-4px;
  top:-2px;
  display: inline-block;
  width: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 10px;
  background: #C74D00;
  color:#fff;
  border-radius: 10px;;
}

.center_char_wrap{
  position: absolute;
  width: 100%;
  top:300px;
  z-index: 2;
}
.center_char_con{
  position: relative;
}
.char_wrap{
  position: absolute;
  top:0px;
  left:0px;
  width: 150px;
  height: 150px;
}
.char_img{
  width: 100%;
}
.char_shadow_img{
  position: absolute;
  width: 162%;
  max-width: 200%;
  left: -20%;
  bottom: -44%;
}

.balloon_wrap{
  position: absolute;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.balloon_img{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
}
.balloon_ico{
  position: absolute;
  top:25%;
  left:30%;
  width: 40%;
  height: 40%;
}
.balloon_icon_text{
  position: absolute;
  display: block;
  top:35%;
  left:0px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.balloon_title{
  position: absolute;
  display: block;
  top:60%;
  left:0px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.balloon_badge{
  position: absolute;
  top:18px;
  right: 24px;
  padding:0px 8px;
  text-align: center;
  line-height: 20px;
  border-radius: 20px;
  background:#C74D00;
  font-size: 15px;
  font-weight: 600;
  color:#fff;
}

.bottom_wrap{
  position: absolute;
  width: 100%;
  top:360px;
  left:0px;
}
.bottom_con{
  position: relative;
  width: 100%;
  height: 120px;
}
.bottom_btn_right_wrap{
  position: absolute;
  right:10px;
  top:0px;
  text-align: right;
  z-index: 2;
}
.bottom_flower_btn{
  text-align: center;
  line-height: 8px;
  font-size: 20px;
  font-weight: 600;
  z-index: 2;
}
.bottom_flower_btn img{
  width: 50px;
}
.bottom_center_text{
  text-align: center;
  color:#000000;
  font-size:18px;
  letter-spacing: 0;
  text-decoration: underline;
  text-decoration-thickness:1px;
  text-decoration-color: green;
}