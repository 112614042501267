.list_wrap{
  position: relative;
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: 0;
  font-size: 12px;
  margin-top:5px;
  background-color: #ececec;
}
.card_group_wrap{
  margin-top:10px;
  background: #fafafa;
  padding-bottom: 8px;
}
.card_group_title{
  position: relative;
  line-height: 22px;
  font-weight: bold;
  font-size: 14px;
  font-weight: bold;
  padding:0px 15px;
  padding-top: 5px;
}
.card_group_title_right_span{
  margin-left: 5px;
  color:#999;
  font-size: 11px;
  font-weight: normal;
}
.card_group_list_wrap{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding:5px 15px;
}
.card_box{
  position: relative;
  width:100%;
  min-height: 60px;
  background: #fff;
  border-radius: 10px;
  border:1px solid #e9e9e9;
  padding: 5px;
  cursor: pointer;
}
.card_box_title{
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color:#232E00;
  margin-top: 5px;
  margin-left:5px;
}
.card_box_date_wrap{
  position: absolute;
  right:10px;
  top:10px;
  text-align: right;
  color: #9BA044;
  font-size: 12px;
}
.card_box_sub_text{
  position: relative;
  margin-top: 5px;
  margin-left:5px;
  font-size: 12px;
  color: #888888;
  height: 22px;
}
.card_box_success_check{
  display: inline-block;
  position: absolute;
  right: 10px;
  top:0px;
  height: 15px;
  width: 15px;
  background: #d9d9d9;
  color:#fff;
  text-align: center;
  line-height: 12px;
  border-radius: 10px;
}
.card_box_success_check_ico{
  height: 11px;
}
.card_box_state_bar_wrap{
  position: relative;
  width: 97%;
  height: 5px;
  margin: 0 auto;
}
.card_box_state_bar_back{
  position: absolute;
  width: 100%;
  height: 5px;
  background: #ddd;
}
.card_box_state_bar{
  position: absolute;
  width: 0%;
  height: 5px;
  background: #abc118;
}
.card_box_state_str_wrap{
  position: relative;
  width: 100%;
  padding:0px 10px;
  height: 20px;
}
.card_box_state_str{
  position: absolute;
  top:2px;
  right: 5px;
  font-weight: bold;
  font-size: 12px;
}
.card_box_state_per_num{
  font-size: 12px;
  color:#b6c35a;
  margin-right: 5px;
}