.card_list_wrap{
  display: flex;
  gap:10px;
  padding:5px;
  flex-direction: column;
  align-items: center;
}
.card_box_wrap{
  border:1px solid #ddd;
  border-radius: 10px;
  padding:8px;
  background: #fff;
  width: 98%;
}
.card_box_row_title{
  font-weight: bold;
  padding-left: 3px;
}
.card_box_row_content{
  padding-left: 10px;
}