.btn-s {
  @apply font-bold rounded focus:ring-1;
  white-space:nowrap;
  padding:0 2px;
  line-height:21px;
  font-size:12px;
}
.btn-s2{
  padding:0px 2px;
  line-height: 20px;
  cursor: pointer;
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: 0;
  font-size:12px;
  font-weight: 400;
  white-space:nowrap;
}
.btn {
  @apply font-bold px-4 py-1 rounded text-sm;
  white-space:nowrap;
  font-size: 13px;
}
.btn-l {
  @apply font-bold px-7 py-3 rounded text-base;
  white-space:nowrap;
  font-size: 16px;
}
.btn-xl {
  @apply font-bold px-7 py-4 rounded text-xl;
  white-space:nowrap;
  font-size: 18px;
}

.btn-box-center{
  @apply flex flex-wrap space-x-1 gap-0.5 justify-center content-center
}
.btn-box-left{
  @apply flex flex-wrap space-x-1 gap-0.5 justify-start content-center
}
.btn-box-right{
  @apply flex flex-wrap space-x-1 gap-0.5 justify-end content-center
}

.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}

.btn-sky{
  background: #0097BA;
  color: #fff;
}

.btn-green{
  background: #3FAC96;
  color:#fff;
}

.btn-yellow {
  @apply text-white bg-yellow-400;
}
.btn-yellow:hover {
  @apply bg-yellow-500;
}

.btn-yellow2 {
  @apply text-white;
  background:#b2c535;
}
.btn-brown:hover {
  background:#b6c654;
}

.btn-yellowish {
  @apply text-white;
  background:#AEBE00;
}
.btn-yellowish:hover {
  background:#8c9900;
}

.btn-yellowish2 {
  @apply text-white;
  background:#3FAC96;
  font-weight: 500;
}
.btn-yellowish2:hover {
  background:#338c7a;
}

.btn-yellowish3 {
  @apply text-white;
  background:#0197BA;
}
.btn-yellowish3:hover {
  background:#007690;
}

.btn-red {
  @apply text-white bg-red-700;
  font-weight: 500;
}
.btn-red:hover {
  @apply bg-red-800;
}

.btn-dark {
  @apply text-white bg-btndark;
}
.btn-dark:hover {
  background-color: #6a6a68;
}

.btn-gray {
  @apply text-white bg-btngray;
}
.btn-gray:hover {
  background-color:#b3b3b3;
}

.btn-brown {
  @apply text-white;
  background:#8e8d07;
}
.btn-brown:hover {
  background:#6d6d04;
}

.btn-line-gray{
  @apply text-gray-600 border-gray-200 shadow-md border-2 dark:text-gray-200;
}
.btn-line-gray:hover{
  @apply text-gray-700 border-gray-100 dark:text-gray-100;
}

.btn-round-yellow {
  @apply text-white bg-yellow-400 rounded-full;
}
.btn-round-yellow:hover {
  @apply bg-yellow-500;
}

.btn-round-green {
  @apply text-white bg-green-600 rounded-full;
}
.btn-round-green:hover {
  @apply bg-green-700;
}

.btn-round-green2 {
  @apply text-white bg-green-500 rounded-full;
}
.btn-round-green2:hover {
  @apply bg-green-600;
}

.btn-round-dark {
  @apply text-white rounded-full;
  background-color: #5e5e5e;
}
.btn-round-dark:hover {
  background-color: #4b4b4b;
}

.btn-round-orange {
  @apply text-white bg-orange-500 rounded-full;
}
.btn-round-orange:hover {
  @apply bg-orange-600;
}

.btn-text-under{
  color: #6F6F6F;
  text-decoration : underline;
  text-underline-offset :1px;
}
.btn-text-under:hover{
  color: #313131;
}

.round_plus_btn_green{
  border-radius: 50%;
  width:20px;
  height:20px;
  line-height: 21px;
  font-weight: 600;
  font-size: 15px;
  color:#fff;
  background: #46b5a8;
  text-align: center;
}