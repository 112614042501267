.sche_search_wrap{
  width: 100%;
}
.sche_search_con{
  margin:0 auto;
  text-align: center;
  line-height: 30px;
  padding-top:10px;
}
.sche_search_arrow_btn{
  margin:0 5px;
  width: 30px;
  line-height: 30px;
}
.sche_search_arrow_btn img{
  width: 100%;
  padding-bottom: 10px;
}
.sche_search_ym_span{
  padding:0px 5px;
  font-size: 20px;
  color:#444c04;
}
.sche_search_select_input{
  padding:0px 5px;
  text-align: center;
  text-align-last: center;
  appearance:none;
  border: 0;
  font-size: 20px;
}
.sche_table{
  border-collapse: collapse;
  width: 100%;
}
.sche_table_th_text_sun{
  color:#E22F04;
}
.sche_table_th_text_sat{
  color:#0058FF;
}
.sche_table_td{
  cursor: pointer;
}
.sche_table_td_title{
  color:#C4C4C4;
  text-align: left;
  font-weight: 500;
  padding:3px;
  font-size: 15px;
}
.sche_table_td_title_select_month{
  color:black;
}
.sche_table_td_title_select_month.sche_table_td_title_sun{
  color:#E22F04;
}
.sche_table_td_title_select_month.sche_table_td_title_sat{
  color:#0058FF;
}
.sche_table_td_title_now_date{
  background: #fbffe0;
}
.sche_table_td_title_select_date{
  color:#737e29;
  border:2px solid #737e29;
}
.sche_table_td_content_wrap{
  min-height: 20px;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  color:#737e29;
  font-weight: bold;
}