.top_time_wrap{
  position: relative;
  height: 60px;
  text-align: left;
  padding:0px 5px;
}
.top_clock_img{
  display: inline-block;
  height: 50px;
  vertical-align: bottom;
}
.top_clock_box{
  display: inline-block;
  font-size: 16px;
  text-align: center;
  margin-left: 7px;
  line-height: 20px;
}
.top_clock_text{
  font-size: 22px;
  font-weight: 600;
}
.top_clock_right{
  position: absolute;
  top: 0px;
  right: 5px;
  height: 60px;
  line-height: 25px;
  font-weight: 600;
}
.top_char_wrap{
  position: relative;
  height: 60px;
  text-align: center;
}
.top_char_img{
  position: absolute;
  top:0px;
  right: 5px;
  height:60px;
}
.top_nut_img{
  position: absolute;
  top:0px;
  right:0px;
  height: 60px;
}

.matter_box_wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
}

.button_area_wrap{
  position: relative;
}
.button_area_wrap table{
  width: auto;
  border-collapse: collapse;
  margin:0 auto;
}
.button_area_button{
  width: 90px;
  height: 70px;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  background: #00A99E;
  color: #fff;
  margin: 5px;
}