.attend_box{
  width: 98%;
  margin:0 auto;
  padding:8px;
  padding-left: 20px;
  position: relative;
  box-shadow: 2px 2px 5px 5px #efefef;
}
.attend_left_bar{
  width: 5px;
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0px;
  top:0px;
  background: #f00;
}
.attend_box_title{
  font-size: 15px;
  font-weight: 500;
}