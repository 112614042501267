.top_wrap{
  background: linear-gradient(253deg, #C8D376 0%, #ABC118 37.41%, #86A609 84.51%);
  padding-bottom: 50px;
}
.top_search_wrap{
  position: relative;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color:#fff;
  font-weight: bold;
}
.top_search_report_btn{
  position: absolute;
  top:0px;
  right: 10px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
}