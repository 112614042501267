.card_group_wrap{
  position: relative;
}
.card_group_item{
  position: relative;
  
}
.card_group_item_title{
  position: relative;
  height: 30px;
  padding:0 15px;
  background: #E0E5E5;
  line-height: 30px;
  font-size: 13px;
  font-weight: 400;
  color:#000;
  border-bottom: 1px solid #bababa;
}
.card_group_item_title_right{
  position: absolute;
  top:0px;
  right: 15px;
}
.group_item_up_down_btn{
  width: 30px;
  font-size: 12px;
  color:#000;
  cursor: pointer;
}
.card_box_list_wrap{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding:10px 10px;
  background: #F1F4F4;
}
.card_box{
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.11);
  font-size: 14px;
  padding:10px;
}
.card_box_title_wrap{
  position: relative;
  line-height: 22px;
  border-bottom: 1px solid #E0E5E5;
}
.card_box_title{
  position: relative;
  font-size: 15px;
  font-weight: 500;
}
.card_box_title_right{
  position: absolute;
  right: 10px;
  top:0px;
  font-size: 13px;
  font-weight: 300;
  cursor: pointer;
}
.card_box_sub_title{
  color:#8F8F8F;
  font-size: 13px;
  line-height: 21px;
  margin-top: 5px;
}
.card_box_todo_wrap{
  position: relative;
  padding: 10px 0px;
  border-bottom: 1px solid #E0E5E5;
}
.card_box_todo_title{
  position: relative;
  color: #0D0D0D;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  height: 25px;
}
.card_box_todo_title_right{
  position: absolute;
  right: 10px;
  top:0px;
  font-weight: 200;
  font-size:13px;
  cursor: pointer;
}
.card_box_todo_progress_title{
  position: relative;
  font-weight: 500;
  font-size: 13px;
  line-height: 23px;
  text-align: left;
}
.card_box_todo_progress_wrap{
  position: relative;
  width: 90%;
  height: 6px;
  border-radius: 5px;
  background: #E0E5E5;
  margin:0 auto;
}
.card_box_todo_progress{
  position: absolute;
  top:0px;
  left:0px;
  border-radius: 5px;
  background: #5CB600;
  height: 100%;
  width: 0%;
}

.mid_todo_row{
  position: relative;
  width: 100%;
  background: #e4e4e4;
  line-height:30px;
  padding-left: 10px;
  cursor: pointer;
}
.mid_todo_row_bar{
  position: absolute;
  width: 5px;
  height: 25px;
  background: #3FAC96;
  left: 0px;
}
.mid_todo_row_title{
  font-size: 13px;
  color:#000;
  font-weight: 500;
}
.mid_todo_row_per{
  position: absolute;
  right:5px;
  font-size: 12px;
  color:#000;
}
.mid_todo_row_home_work_file_ico{
  margin-left: 5px;
  font-size: 12px;
  color:#fff;
  font-weight: 500;
  background: #0fc40f;
  border-radius:20px;
  padding:0px 5px;
  padding-right: 6px;
}
.mid_todo_row_detail_row td{
  font-size: 12px;
  color:#555;
  line-height: 21px;
}

.card_box_attend_wrap{
  padding:5px 0px;
}
.card_box_attend_title{
  position: relative;
  height: 25px;
  line-height: 25px;
  font-size: 15px;
  font-weight: 500;
}
.card_box_attend_title_right{
  position: absolute;
  top:0px;
  right: 15px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
}
.card_box_attend_btn_wrap{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:5px;
  padding-top:5px;
}
.card_box_attend_btn{
  width: 22%;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
}

/* list_row */
.list_row{
  position: relative;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:6px;
  line-height: 22px;
}
.list_row .checkbox_wrap{
  flex-shrink: 0;
  text-align: left;
}
.list_row .list_row_title_wrap{
  width: 100px;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
}
.list_row .list_row_percent_wrap{
  width: 60px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #ccc;
  text-decoration-line: underline;
}
.list_row .list_row_btn_wrap{
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 3px;

}
.list_row_attend_btn{
  width: 50px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
}