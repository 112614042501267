.stu_snb_area_wrap{
  border-bottom: 1px solid #dfdfdf;
}
.stu_snb_area_wrap .stu_snb_area{
  @apply flex flex-wrap flex-row;
  width: 100%;
  margin:0 auto;
}
.stu_snb_area .stu_snb_item{
  cursor: pointer;
  font-size: 20px;
  height: 45px;
  line-height: 45px;
  color:#A4AB4B;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
  border-bottom: 4px solid transparent;
}
.stu_snb_area .stu_snb_item.active{
  color:#717923;
  border-bottom: 4px solid #889130;
}