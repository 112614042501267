.home_work_wrap{
  position: relative;
  margin:0 auto;
  margin-top: 360px;
  width: 95%;
  background: #fff;
  padding:10px;
  border-radius: 10px;
}
.home_work_title_div{
  position: relative;
  line-height: 30px;
  padding-left: 32px;
  font-size: 15px;
  font-weight: bold;
  color: #3D2C24;
}
.home_work_title_more{
  position: absolute;
  right: 0px;
  font-family: 'nanum_slow';
  font-size: 17px;
  color:#ADB86A;
  cursor: pointer;
}
.home_work_list_wrap{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top:15px;
}
.home_work_list_row{
  position: relative;
  width: 100%;
  padding-left: 65px;
  line-height: 35px;
  background: #F2F4E9;
  border-radius: 10px;
  font-size: 15px;
  color:#252A16;
  font-weight: 500;
}
.home_work_list_row_front_text{
  position: absolute;
  left: 0px;
  top:0px;
  line-height: 35px;
  padding:0 10px;
  background: #704F1A;
  color:#fff;
  border-radius: 10px;
  font-size: 16px;
  font-weight: normal;
}