.slide_wrap{
  display: flex;
  gap:5px;
  flex-wrap: wrap;
}
.slide_item{
  line-height: 25px;
  background: #fff;
  border:1px solid #ddd;
  border-radius: 5px;
  padding:5px;
  text-align: center;
  height: 35px;
  min-width: 70px;
  overflow: hidden;
  cursor: pointer;
}
.slide_item.active{
  background: #9ca935;
  color:#fff;
}