.bar_wrap{
  position: relative;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  padding:0 15px;
  margin-top:2px;
  font-weight: 500;
  color:#000;
  border-bottom: 0.5px solid rgba(218, 218, 218, 0.50);
  background: #FFF;
}
.bar_right_span{
  position: absolute;
  top:5px;
  right: 15px;
}