.list-table-div{
  width: 100%;
  overflow: auto;
  font-family: 'Noto Sans Korean','Noto Sans KR', sans-serif;
  letter-spacing: 0;
}
.list-table-div table{
  width: 100%;
  border-collapse: collapse;
}
.list-table-div .row-col-div{
  /* display: inline-block; */
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
}

.list-table-div thead tr{
  background: #e3e3e3;
}
.dark .list-table-div thead tr{
  background: #101010;
}

.list-table-div tbody tr{
  background: rgb(255, 255, 255);
}
.list-table-div tbody tr:nth-child(even){
  background: #fcfcfc;
}
.dark .list-table-div tbody tr{
  background: #181d1f;
}
.dark .list-table-div tbody tr:nth-child(even){
  background: #222628;
}

.list-table-div tbody tr.active{
  background: #cee9ff;
}
.list-table-div tbody tr.active td{
  color:rgb(43, 43, 43);
}
.dark .list-table-div tbody tr.active{
  background: #6ebeff;
}

.list-table-div th,.list-table-div td{
  @apply text-sm;
  border: 1px solid rgb(177, 176, 176);
  color:black;
  padding:2px 3px;
}
.dark .list-table-div th, .dark .list-table-div td{
  color:rgb(255, 255, 255);
}

.list-table-div th{
  font-weight: bold;
}
.dark .list-table-div th{
  font-weight: bold;
}


.list-table-div.gray_table thead tr{
  background: #f3f2f2;
}
.list-table-div.gray_table th,.list-table-div.gray_table td{
  border: 1px solid rgb(196, 195, 195);
  border-left:0px;
  border-right:0px;
  color:black;
  padding:2px 3px;
  font-size:14px;
  text-align: center;
}
.dark .list-table-div.gray_table td{
  color:#fff;
}

.list-table-div .row-input{
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg text-sm
  focus:ring-blue-500 
  focus:border-blue-500 
  w-full p-1 
  dark:bg-gray-700 
  dark:border-gray-600 
  dark:placeholder-gray-400 
  dark:text-white 
  dark:focus:ring-blue-500 
  dark:focus:border-blue-500;
}

.list-table-div .list_empty_content_div{
  @apply text-center p-3;
}