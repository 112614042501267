.notice_list{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.notice_box{
  padding:10px;
  box-shadow: 0px 0px 5px #c2c2c2;
}
.notice_box_title{
  font-size: 14px;
  font-weight: 600;
}
.notice_box_content{
  margin-top: 5px;
  color:"#666";
}
.notice_box_bottom{
  margin-top: 5px;
  display: flex;
  gap: 10px;
  color:#999;
  font-size: 12px;
}