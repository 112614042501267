.list_wrap{
  position: relative;
  border-top:1px solid #E0E5E5;
}
.card_box_wrap{
  position: relative;
  padding:10px 15px;
  border-bottom:8px solid #E0E5E5;
  font-size: 14px;
}
.card_title_wrap{
  height: 45px;
  line-height: 21px;
  display: flex;
  gap: 8px;
}
.card_title_img_wrap{
  width: 45px;
  height: 45px;
  border-radius: 30px;
  background: lightgray 50%;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
}
.card_title{
  color:#3FAC96;
  font-size: 15px;
  font-weight: 500;
}
.card_sub_title{
  color: #898989;
  font-size: 13px;
  font-weight:500;
}
.card_title_right{
  flex-grow: 1;
  text-align: right;
  font-size: 11px;
  font-weight: 400;
  color: #909090;
}
.card_box_content_wrap{
  font-size: 15px;
  color:#000;
  font-weight: 300;
  padding-top:10px;
}
.card_box_content_title{
  color:#898989;
  font-weight: 500;
}
.card_box_img_wrap{
  text-align: center;
}
.card_box_img_wrap img{
  width: 100%;
}