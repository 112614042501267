.main_wrap{
  position: relative;
  font-family: 'Noto Sans Korean','Noto Sans KR', sans-serif;
  letter-spacing: 0;
  font-size: 15px;
  padding-bottom: 20px;
}
.con_wrap{
  padding: 0 15px;
  position: relative;
}
.top_select_wrap{
  position: relative;
  margin-top: 50px;
  color:#fff;
}
.top_child_manage_btn{
  position: absolute;
  top:0px;
  right:15px;
  width: 80px;
  height: 80px;
  text-align: center;
  cursor: pointer;
}
.todo_progress_wrap{
  margin-top: 5ox;
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  gap:10px;
  color: #F5F7FB;
  font-size: 12px;
}
.todo_progress_bar_back{
  margin-top: 6px;
  position: relative;
  display: inline-block;
  width: 33%;
  border-radius: 10px;
  height: 6px;
  background: #c3c3c3;
  opacity: 0.9;
}
.todo_progress_bar{
  position: absolute;
  left:0px;
  top:0px;
  height: 100%;
  width: 0px;
  background: #F5F7FB;
  border-radius: 10px;
  z-index: 1;
}

.menu_arr_wrap{
  position: relative;
  margin-top: 17px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap:8px;
}
.menu_item{
  width: 75px;
  height: 75px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  line-height: 28px;
  font-size: 13px;
  color: #000;
  padding-top:12px;
  cursor: pointer;
}

.today_sche_wrap{
  position: relative;
  margin-top: 10px;
}
.today_sche_title{
  position: relative;
  font-size: 16px;
  font-weight: 500;
}
.today_sche_title_right_btn{
  position: absolute;
  right: 0;
  top:5;
  color:#787878;
  font-size: 12px;
  font-weight: 400;
}
.today_sche_slide_wrap{
  width: 100%;
  overflow: auto;
}
.today_sche_slide_con{
  display: flex;
  margin-top:5px;
  padding-bottom: 10px;
  gap:15px;
}
.today_sche_slide_item{
  width: 140px;
  height: 162px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
}
.today_sche_slide_item:first-child{
  margin-left: 15px;
}
.today_sche_item_title_wrap{
  width: 100%;
  height: 50px;
  border-radius: 15px 15px 0px 0px;
  background: #5F7BE4;
  color: #FFF;
  text-align: center;
  letter-spacing: -0.333px;
  padding-top:6px;
}
.today_sche_item_attend_wrap{
  line-height: 24px;
  border-bottom: 1px solid #E0E5E5;
  font-size: 13px;
  color:#040404;
  padding:2px 15px;
}
.today_sche_item_attend_btn_wrap{
  display: flex;
  justify-content: center;
  gap:12px;
  padding-top: 10px;
}

.recent_study_wrap{
  position: relative;
  margin-top: 10px;
}
.recent_study_title_wrap{
  font-size: 16px;
  font-weight: 500;
  color:#000;
}
.study_state_box{
  height: 80px;
  border-radius: 15px;
  background: #5F7BE4;
  padding:10px 18px;
  margin-top:10px;
}
.study_state_box_title_wrap{
  font-size: 16px;
  color: #FFF;
  font-weight: 500;
  letter-spacing: -0.333px;
}
.study_state_percent_wrap{
  margin-top: 5px;
  color: #fff;
}
.study_state_percent_bar_back{
  position: relative;
  width: 98%;
  height: 7px;
  border-radius: 99px;
  background: #bad1ff;
}
.study_state_percent_bar{
  position: absolute;
  top:0px;
  left: 0px;
  width: 0%;
  height: 7px;
  border-radius: 99px;
  background: #F5F7FB;
}