.file_group_wrap{
  position: relative;
  letter-spacing: 0;
  background-color: #ececec;
  padding-bottom: 15px;
}
.file_top_title{
  position: relative;
  line-height: 22px;
  font-weight: 500;
  font-size: 15px;
  padding:0px 15px;
  padding-top: 5px;
  line-height: 30px;
}
.file_top_title_right{
  position: absolute;
  top:3px;
  right: 15px;
}
.file_top_plus_btn{
  width: 28px;
  height: 28px;
  background: #b3c540;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  line-height: 26px;
  font-size: 20px;
  color:#fff;
  cursor: pointer;
}
.file_group_list{
  margin-top:10px;
  padding:0px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.file_group_list_item{
  background: #fff;
  line-height: 30px;
  padding:0 10px;
  border-radius: 10px;
  font-size: 15px;
}