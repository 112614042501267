.back_sky_area{
  width: 100%;
  height: 230px;
  background: #E4EFE9;
}
.main_mid_back_img{
  position: absolute;
  top:180px;
  width: 100%;
  z-index: 1;
}
.main_cloud{
  position: absolute;
  width: 50px;
}
.main_weed{
  position: absolute;
  width: 20px;
}