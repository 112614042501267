.m_main_body{
  @apply absolute h-screen w-full overflow-auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}
.m_main_body.is_home_body{
  background: #c2cd74;
}
.m_main_body_top_empty{
  position: fixed;
  top:-300px;
  height:300px;
  width: 100%;
  background:#ffffff;
}
.is_home_body .m_main_body_top_empty{
  background: #c2cd74;
}

/*header*/
#m_header{
  position: fixed;
  width: 100%;
  top:0px;
  left:0px;
  height: 55px;
  border-bottom:1px solid #e0e3c7;
  background: #ffffff;
  z-index: 1;
}
.m_header_simple{
  position: fixed;
  width: 100%;
  top:0px;
  left:0px;
  background: #ffffff;
  z-index: 1;
}
.is_home_body #m_header{
  border-bottom:1px solid #d1da8b;
  background: #c2cd74;
}
.m_header_con{
  width: 94%;
  margin:0 auto;
  height: 55px;
  line-height: 55px;
  display: flex;
  flex-direction: row;
}
#m_header .m_header_left{
  width:65px;
  line-height: 55px;
}
#m_header .m_header_center{
  flex-grow: 1;
}
.m_header_title{
  position:absolute;
  width: 50%;
  height: 100%;
  text-align: center;
  font-size: 20px;
  color:#444c04;
  z-index: 1;
  top:0px;
  left:25%;
  line-height: 55px;
}
.is_home_body .m_header_title{
  color: #fff;
}
#m_header .m_header_right{
  width:65px;
  line-height: 55px;
}
.m_header_s_menu{
  position:absolute;
  width: 50px;
  height: 200px;
  top:55px;
  right:2%;
  z-index:10;
}
.m_header_s_menu_back{
  position:absolute;
  width: 100%;
  height: 100%;
}


/*container*/
#m_container{
  flex-grow: 1;
  padding-bottom: 75px;
  margin-top:56px;
}
#m_container .m_container_con{
  position: relative;
  width: 98%;
  margin:0 auto;
  height: 100%;
  overflow: auto;
}
.list_con_wrap{
  width: 100%;
  min-height: 80vh;
  background: #fff;
  border-radius: 25px;
  padding:10px;
}

/*footer*/
#m_footer{
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 75px;
  border-top:1px solid #e2e2e2;
  background: #fafafa;
}
.is_home_body #m_footer{
  border-top:1px solid #d1da8b;
  background: #D2DC8A;
}
#m_footer .m_footer_con{
  width: 94%;
  margin:0 auto;
  line-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.m_footer_item{
  flex-grow: 1;
  text-align: center;
  line-height: 16px;
  padding-top:10px;
  font-size:16px;
  color:#a3b32e;
  cursor: pointer;
  position: relative;
}
.m_footer_item.active{
  color:#a3b32e;
}
.is_home_body .m_footer_item{
  color:#fff;
}
.is_home_body .m_footer_item.active{
  color:#a3b32e;
}
.m_footer_item img{
  height: 37px;
}
.m_footer_manage_item{
  flex-grow: 1;
  text-align: center;
  line-height: 25px;
  padding-top:12px;
  font-size:12px;
  color:#8F8F8F;
  cursor: pointer;
  position: relative;
}
.m_footer_manage_item.active{
  color:#3FAC96;
}
.is_home_body .active{
  color:#fff;
}