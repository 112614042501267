.back_wrap{
  position: absolute;
  top:0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}
.back_wrap_con{
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #6074E2 0%, #5683DB 78.12%);
}
.back_circle{
  position: absolute;
  width: 403px;
  height: 403px;
  border-radius: 403px;
  border: 90px solid #FFF;
  opacity: 0.1;
}
.back_main_con_div{
  position: absolute;
  background: #FFF;
  top:430px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}