.home_work_wrap{
  width: 85%;
  margin: 0 auto;
  margin-top: 10px;
}
.title_wrap{
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color:#0b0b36;
}
.sub_title{
  margin-top: 5px;
  font-size: 14px;
}
.sub_img_wrap{
  display: flex;
  flex-wrap: wrap;
  gap:10px;
  justify-content: center;
  flex-direction: column;
}
.sub_img_wrap_item{
  position: relative;
  text-align: center;
  min-width: 100px;
  padding:10px;
  background: #fcfffb;
  border-radius: 10px;
}
.sub_img_wrap_item img{
  max-width: 100%;
}
.sub_audio_wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:10px;
}
.sub_audio_wrap_item{
  position: relative;
  text-align: center;
  width: 100%;
  padding:10px;
  background: #999999;
  color:#fff;
  border-radius: 10px;
}
.sub_audio_wrap_item audio{
  display: inline-block;
}
.sub_video_wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:10px;
}
.sub_video_wrap_item{
  position: relative;
  text-align: center;
  width: 100%;
  padding:10px;
  background: #999999;
  color:#fff;
  border-radius: 10px;
}
.sub_video_wrap_item video{
  display: inline-block;
}