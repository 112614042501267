.tab_wrap{
  display: flex;
  flex-direction: row;
  height: 38px;
  line-height: 38px;
  border-bottom: 1px solid #E0E5E5;
  padding:0 15px;
}
.tab_wrap .item{
  flex-grow: 1;
  text-align: center;
  color:#000;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.tab_wrap .item.active{
  color:#3FAC96;
  border-bottom: 2px solid #3FAC96;
}