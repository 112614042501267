.leave_info_wrap{
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 12px;
  padding:5px;
  margin-top: 10px;
  border:1px solid rgb(198, 198, 198);
  border-radius: 5px;
  width: 100%;
}
.leave_info_title{
  margin-top: 10px;
}
.leave_info_agree_wrap{
  margin-top: 20px; 
}