.menu_wrap{
  display: flex;
  gap:10px;
}
.menu_box{
  width: 100px;
  height: 50px;
  background-color: #f1f1f1;
  border-radius: 10px;
  cursor: pointer;
}
.menu_box_inner{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
}
.menu_box_inner_text{
  color: #333;
}