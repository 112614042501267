.card_group_list_wrap{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.card_list_wrap{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: row;
}
.card_box{
  flex-grow: 1;
  margin:0px 2px;
  border:1px solid #ddd;
  border-radius: 5px;
  padding:5px;
  background: #fff;
}