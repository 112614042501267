.title_box{
  padding:5px;
  box-shadow: 0px 0px 5px 0px #9e9e9e1a;
}
.title{
  font-size: 15px;
  font-weight: 400;
  color: #565656;
}
.title_name{
  position: relative;
  font-size: 17px;
  font-weight: 600;
  color: #0a0a0a;
}
.right_stu_name{
  position: absolute;
  right: 0px;
  top:2px;
  font-size: 15px;
  font-weight: 500;
  color:#111111;
}
.supply_money_wrap{
  position: relative;
  text-align:right;
  font-size: 15px;
  font-weight: 600;
  color: #2c2c2c;
}
.left_money_pre_str{
  position: absolute;
  top:6px;
  left:0px;
  color:#666666;
  font-weight: 500;
  font-size: 14px;
}
.supply_money{
  font-size: 20px;
  font-weight: 600;
  color: #15c257;
}