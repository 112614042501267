.search_wrap{
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 30px;
  line-height: 30px;
  justify-content: center;
  font-family: 'Noto Sans Korean','Noto Sans KR', sans-serif;
  letter-spacing: 0;
  font-size: 13px;
}
.search_input{
  border:1px solid #ddd;
  min-width: 50px;
  height: 30px;
  line-height: 30px;
  padding:0px 3px;
  font-size: 14px;
}
.search-input{
  font-size: 13px;
}
.basic_search_item_book_mark{
  color:blue;
  padding: 3px;
  cursor: pointer;
  line-height: 25px;
  font-size: 15px;
  font-weight: bold;
}