.stu_list_wrap{
  display: flex;
  gap:5px;
  min-height: 40px;
  padding:10px 5px;
  border:1px solid #eee;
}
.stu_box{
  display: inline-block;
  line-height: 30px;
  padding:0px 5px;
  border-radius: 5px;
  border:1px solid #ccc;
}