.etc_menu_wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding:10px;
}
.etc_menu_item{
  width:85px;
  height: 60px;
  background: #9ca935;
  text-align: center;
  border-radius: 5px;
  line-height: 60px;
  cursor: pointer;
  margin:5px;
}
.item_title{
  color:white;
  font-size: 15px;
}