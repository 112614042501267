.list_board_div{
  width: 100%;
}
.list_board_div .list_board_row{
  display: flex;
  flex-direction: row;
  min-height: 40px;
  padding:5px 0px;
}
.list_board_row .list_board_icon_box{
  width: 40px;
  text-align: center;
  height:40px;
}
.list_board_row .list_board_title_wrap{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.list_board_title_div{
  color:#434c03;
  font-size:16px;
  line-height: 24px;
  font-weight: 600;
  flex-grow: 1;
}
.list_board_sub_title_div{
  color:#434c03;
  font-size:16px;
}
.list_board_opt_wrap{
  width: 80px;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}
.list_board_opt_top{
  text-align: right;
  font-size: 16px;
  flex-grow: 1;
}
.list_board_opt_bottom{
  text-align: right;
  font-size: 14px;
}

.list_board_div .msg_new{
  font-size: 12px;
  background: red;
  color: white;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  display: inline-block;
  line-height: 21px;
  text-align: center;
  font-weight: bold;
}
.list_board_div .list_board_empty_row{
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-size: 18px;
  font-weight: 500;
}