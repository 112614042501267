.join_wrap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height:100%;
  width: 100%;
}
.join_con{
  width: 85%;
  align-self: center;
}
.join_title{
  font-size: 55px;
  text-align: center;
  line-height: 55px;
}
.join_sub_title{
  font-size: 25px;
  text-align: center;
  letter-spacing: 0px;
}
.join_input{
  margin-top:28px;
  border-radius: 15px;
  padding:6px 15px;
  width: 100%;
  text-align: center;
}
.join_btn{
  display: inline-block;
  width: 100%;
  border-radius: 15px;
  background: #96a23f;
  padding:4px;
  color:#fff;
  font-size:26px;
  cursor: pointer;
  margin-top:15px;
}
