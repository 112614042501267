.header{
  border-bottom: 0.5px solid rgba(218, 218, 218, 0.50);
  background: #FFF;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.03);
}
.header_con{
  position: relative;
  height: 40px;
  line-height: 40px;
  width: 94%;
  margin:0 auto;
  text-align: center;
  font-size: 15px;
}
.header_left{
  position: absolute;
  top:0px;
  left:0px;
  cursor: pointer;
}
.header_right{
  position: absolute;
  right:0px;
  top:0px;
  cursor: pointer;
}