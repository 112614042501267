.attend_bar_wrap{
  display:flex;
  flex-direction: row;
  border-bottom: 1px solid #e4e4e4;
}
.attend_bar_wrap .item{
  flex-grow: 1;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  padding:2px 0px;
}
.attend_bar_item_title{
  color:#8F8F8F;
  font-size: 11px;
  font-weight: 300;
}
.attend_bar_item_count{
  font-size: 15px;
  font-weight: 500;
}

.search_wrap{
  display: flex;
  justify-content: center;
  padding:4px 0px;
}
.search_wrap input{
  font-size: 14px;
  height: 25px;
  line-height: 25px;
}
.add_plan_btn{
  font-size:14px;
  color: #fff;
  font-weight:500;
  background: #0771da;
  border-radius: 5px;
  padding:0px 5px;
  line-height: 28px;
  cursor: pointer;
}