.row_box_wrap{
  display: flex;
  flex-direction: column;
  gap:15px;
  padding: 0px 5px;
}
.row_box{
  position: relative;
  width: 100%;
  display: inline-block;
  font-size: 22px;
  padding: 8px;
  border-radius: 5px;
  background: #eeeeee;
}
.row_box_title{
  white-space: nowrap;
  overflow: hidden;
}
.row_box_move_btn{
  display: inline-block;
  text-align: center;
  font-size: 22px;
  height: 40px;
  line-height: 35px;
  color:#fff;
  background: #709638;
  padding:0px 8px;
  border-radius: 8px;
  cursor: pointer;
}