.find_stu_wrap{
  font-size: 15px;
  padding:15px 15px;
}
.header_wrap{
  display: flex;
  height: 50px;
  line-height: 50px;
  width: 100%;
}
.header_wrap_btn{
  padding:0 15px;
}
.header_wrap_center{
  text-align: center;
  color:#000;
  font-size: 17px;
  flex-grow: 1;
}
.search_wrap{
  display: flex;
  height: 45px;
  width: 100%;
}
.search_stu_name_input{
  flex-grow: 1;
  border:1px solid #CAD5D2;
}
.search_wrap_search_btn{
  width: 73px;
  height: 45px;
  flex-shrink: 0;
  background: #3FAC96;
  color:#fff;
  text-align: center;
  line-height: 45px;
}

.stu_list_table_wrap{
  max-height: 70vh;
  overflow: auto;
  margin-top:20px;
}
.stu_list_table{
  width: 100%;
  border-collapse: collapse;
}
.stu_list_table td{
  font-size: 15px;
  line-height: 23px;
  height: 40px;
  text-align: left;
  font-weight: 300;
  border:0;
}
.stu_row_tr{
  position: relative;
}
.stu_row_tr.active{
  background: #e9ffe3;
}