.msg_wrap{
  width:100%;
}

.msg_row{
  width:100%;
  display: flex;
  flex-direction: row;
  padding: 5px 2px;
  gap:5px;
}

.msg_profile_wrap{
  width: 40px;
  height: 40px;
  text-align: center;
  overflow: hidden;
}
.msg_profile_img{
  height: 100%;
}
.msg_content_wrap{
  flex-grow: 1;
  line-height: 24px;
}
.msg_content{
  font-size: 16px;
  color:#898989;
}
.msg_right_wrap{
  width:80px;
  height: 50px;
  text-align: right;
  overflow: hidden;
  line-height: 24px;
}
.msg_new{
  font-size: 12px;
  background: red;
  color: white;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  display: inline-block;
  line-height: 21px;
  text-align: center;
  font-weight: bold;
}
.msg_time{
  font-size: 12px;
}