.file_area_wrap{
  position: relative;
  width: 94%;
  margin:0 auto;
  padding:5px;
}
.file_list_wrap{
  width: 100%;
  margin-bottom: 10px;
}
.file_form_wrap{
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: nowrap;
}

.file_form_item{
  position: relative;
  min-height: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #373737 0%, #8F8F8F 100%);
  align-items: center;
  color: #fff;
  border-radius: 5px;
}