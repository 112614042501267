.file_area_wrap{
  position: relative;
  width: 94%;
  margin:0 auto;
  padding:5px;
}
.file_list_wrap{
  width: 100%;
  overflow: auto;
}
.file_form_wrap{
  height: 130px;
  font-size: 14px;
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
}

.file_form_item{
  position: relative;
  height: 130px;
  width: 130px;
  min-width: 130px;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #373737 0%, #8F8F8F 100%);
  align-items: center;
  color: #fff;
}